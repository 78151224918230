import styles from "./index.module.scss";
import Image from "@/components/Atoms/Image";
import { ListRow } from "@/components/Atoms/List";
import { Item } from "@/components/Atoms/Item";
import CustomLink from "@/components/Atoms/CustomLink";
import { FeaturedWarning } from "@/components/Atoms/Paragraph";
import GeoWrapper from "../GeoConditional";

interface Props {
  data: any;
  route: string;
}

export default function Footer({ data, route }: Props) {
  const year = new Date().getFullYear();
  const footerContent = data.footerContent?.replace(/\{year\}/, year);

  const style = {
    "--bg": data.footerBackgroundColor?.color.hex,
    "--color": data.footerTextColor?.color.hex,
  } as React.CSSProperties;

  const standardColor = { color: { hex: "#000" } };

  return (
    <footer
      style={style as React.CSSProperties}
      className={` ${
        data.addSpacer && route != "/age-gate" ? "pt-12" : "pt-4"
      } w-full ${styles.footer}  ${
        route != "/age-gate" ? "relative" : "relative pt-2  bottom-0"
      }`}
    >
      {route != "/age-gate" ? (
        <>
          {data.addSpacer && (
            <div
              style={style as React.CSSProperties}
              className={` ${styles.logoSpacer}   w-full h-4 absolute translate-y-[-100%] flex flex-row justify-center `}
            >
              <Image data={data.spacerLogo} />
            </div>
          )}

          <div
            className={` ${
              data.navigationLinksAlign == "left"
                ? "justify-between"
                : "justify-center "
            } container flex gap-12 flex-col lg:flex-row mx-auto border-b border-solid border-white/20 py-4 lg:py-2  `}
          >
            {data.hasSocial && (
              <div
                className={`${
                  data.navigationLinksAlign == "left"
                    ? "hidden"
                    : "hidden lg:flex min-w-[5rem]"
                }`}
              ></div>
            )}
            <div className="flex flex-col  justify-center items-center gap-4">
              <ListRow tag={""} style={data.navigationLinksAlign}>
                {data.hasNav && (
                  <>
                    {data.navLinks.map((entry: any, i: number) => (
                      <Item
                        className="brandNavFooter"
                        style="noWrap"
                        key={i}
                        data={entry}
                        color={undefined}
                      />
                    ))}
                  </>
                )}
                <GeoWrapper
                  initial={data?.countryAvailability}
                  country={data?.countries}
                  list={data?.listOfCountries}
                >
                  {" "}
                  {data.addBuyCta && (
                    <a
                      className="brandCall"
                      style={{ color: data.ctaColor?.color.hex }}
                      href={data.ctaLink}
                      target="_blank"
                    >
                      {data.ctaLabel?.label}
                    </a>
                  )}
                </GeoWrapper>
              </ListRow>
              {data.regulationFile && (
                <a
                  href={data.regulationFile.url}
                  className="brandNavFooter"
                  target="_blank"
                >
                  {data.contestLabel?.label}
                </a>
              )}
            </div>

            {data.hasSocial && (
              <div className=" xs:flex-col xs:justify-center xs:gap-y-6 flex-row justify-around mt-4 mb-6 min-w-[5rem] ">
                <span
                  className={
                    data.socialIconsDisplay === "mobile"
                      ? "flex  lg:hidden"
                      : data.socialIconsDisplay === "desktop"
                      ? "hidden lg:flex"
                      : "flex"
                  }
                >
                  <ListRow style="fixedRow" tag={""}>
                    {data.socialLinks.map((entry: any, i: number) => {
                      const style = {
                        "--color": data.footerTextColor.color?.hex,
                        "--svg": `url(${entry.image?.url})`,
                      } as React.CSSProperties;
                      return entry.swapImage ? (
                        <CustomLink
                          target="_blank"
                          key={i}
                          href={`${entry.externalUrl}`}
                          external={"true"}
                        >
                          <div
                            style={style as React.CSSProperties}
                            className={
                              data.overwriteSocialIconsColor
                                ? styles.socialIcon
                                : styles.socialIconDefault
                            }
                          ></div>
                        </CustomLink>
                      ) : (
                        <Item
                          style="noWrap"
                          key={i}
                          data={entry}
                          color={undefined}
                        />
                      );
                    })}
                  </ListRow>
                </span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="container flex gap-12 flex-col lg:flex-row mx-auto border-b border-solid border-white/20 py-4 lg:py-2 ">
          <ListRow tag={""} style={data.navigationLinksAlign}>
            {data.hasNav && (
              <>
                {data.navLinks
                  .filter(
                    (x: any) =>
                      x.slug == "privacy-policy" ||
                      x.slug == "terms-conditions" ||
                      x.slug == "terms-and-conditions-of-use"
                  )
                  .map((entry: any, i: number) => (
                    <Item
                      className="brandNavFooter"
                      style="noWrap"
                      key={i}
                      data={entry}
                      color={undefined}
                    />
                  ))}
              </>
            )}
          </ListRow>
        </div>
      )}

      <div className=" flex flex-col-reverse lg:flex-row w-full justify-between container mx-auto items-centeZr py-2 ">
        <div>
          <div className="text-center md:text-left brandNavFooter mb-1">
            {footerContent}
          </div>
        </div>
        <div className="hidden lg:flex mb-12 md:mb-0 flex-col items-center md:flex-row gap-6 lg:gap-16">
          {data.links.map((entry: any, i: number) => {
            if (entry.externalLink) {
              return (
                <a
                  key={i}
                  target={entry.newWindow && "_blank"}
                  href={entry.externalUrl}
                >
                  <Image className="max-w-[8rem]" data={entry.image} />
                </a>
              );
            } else {
              <Image key={i} data={entry.image} />;
            }
          })}
        </div>
        <div className="flex lg:hidden mb-12 md:mb-0 flex-col items-center  md:flex-row gap-12  py-6">
          <a
            className="flex flex-col-reverse justify-center items-center gap-4 max-w-[10.5rem]"
            target={data.links[3]?.newWindow && "_blank"}
            href={data.links[3]?.externalUrl}
          >
            <Image className="max-w-[10rem]" data={data.links[3]?.image} />
          </a>
          <div className="flex flex-row gap-6">
            <a
              className="flex flex-col-reverse justify-center items-center gap-4 max-w-[10.5rem]"
              target={data.links[1]?.newWindow && "_blank"}
              href={data.links[1]?.externalUrl}
            >
              <Image className="max-w-[10rem]" data={data.links[1]?.image} />
            </a>
            <a
              className="flex flex-col-reverse justify-center items-center gap-4 max-w-[10.5rem]"
              target={data.links[2]?.newWindow && "_blank"}
              href={data.links[2]?.externalUrl}
            >
              <Image className="max-w-[10rem]" data={data.links[2]?.image} />
            </a>
          </div>
          <a
            className="flex flex-col-reverse justify-center items-center gap-4 max-w-[10.5rem]"
            target={data.links[0]?.newWindow && "_blank"}
            href={data.links[0]?.externalUrl}
          >
            <Image className="max-w-[10rem]" data={data.links[0]?.image} />
          </a>
        </div>
      </div>
      <div className="flex   bg-white justify-center py-2">
        <FeaturedWarning
          copy={data.disclaimer.label}
          color={standardColor?.color}
          layout={undefined}
        />
      </div>
    </footer>
  );
}
